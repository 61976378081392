/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        fb_order: {
            fb_matches: [],
        },
        androidVersion: '1.1.0',
        authUser: JSON.parse(localStorage.getItem("userData")) || {},
        amount: 0.0,
        totalWithdraw: 0.0,
        betAmount: null,
        twod_slip_data: [],
        threed_slip_data: [],
        twod_numbers: [],
        threed_numbers: [],
        language: localStorage.getItem("language") || "mm",
        music: false,
        iframeURL: null,
        isCardGame: false,
        sideNav: 'lottery',
        morningDrawTime: '',
        eveningDrawTime: '',
    },
    mutations: {
        SET_USER(state, value) {
            state.authUser = value;
        },
        setIframeURL(state, value) {
            state.iframeURL = value;
        },
        setIsCardGame(state, value) {
            state.isCardGame = value;
        },
        setTotalWithdraw(state, value) {
            state.totalWithdraw = value;
        },
        setMusic(state, value) {
            state.music = value;
        },
        setBetAmount(state, value) {
            state.betAmount = value;
        },
        setAmount(state, value) {
            state.amount = value;
        },
        setTwodSlip(state, value) {
            state.twod_slip_data = value;
        },
        setThreedSlip(state, value) {
            state.threed_slip_data = value;
        },
        setTwodnumbers(state, value) {
            state.twod_numbers = value;
        },
        setThreednumbers(state, value) {
            state.threed_numbers = value;
        },
        setLanguage(state, value) {
            state.language = value;
        },
        setIsDeposits(state, value) {
            state.isDeposits = value;
        },
        setIsWithdraws(state, value) {
            state.isWithdraws = value;
        },
        setWholeFBMatches(state, data) {
            state.fb_order.fb_matches = [];
            state.fb_order.fb_matches = data;
        },
        setFBMatches(state, value) {
            state.fb_order.fb_matches.push(value);
        },
        updateFBMatches(state, data) {
            state.fb_order.fb_matches.splice(data.index, 1);
            state.fb_order.fb_matches[data.index] = data.value;
        },
        removeFBMatch(state, index) {
            state.fb_order.fb_matches.splice(index, 1);
        },
        updateFBOrder(state, data) {
            state.fb_order[data.type] = data.value;
        },
        updateSideNav(state, data) {
            state.sideNav = data;
        },
        setTwodDrawTime(state, data) {
            
            // Step 1: Define 5:30 AM UTC
            let utcTime = "05:30:00"; // 5:30 AM UTC
            let utcDate = new Date(`1970-01-01T${utcTime}Z`); // Create a Date object in UTC

            // Step 2: Get the user's time zone
            let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Step 3: Convert 5:30 AM UTC to the user's local time
            let options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true, // Use 24-hour format
                timeZone: userTimeZone // Set the user's time zone
            };

            state.morningDrawTime = utcDate.toLocaleString('en-US', options);


            // Step 1: Define 5:30 AM UTC
            utcTime = "10:00:00"; // 5:30 AM UTC
            utcDate = new Date(`1970-01-01T${utcTime}Z`); // Create a Date object in UTC

            // Step 2: Get the user's time zone
            userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Step 3: Convert 5:30 AM UTC to the user's local time
            options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true, // Use 24-hour format
                timeZone: userTimeZone // Set the user's time zone
            };
            state.eveningDrawTime = utcDate.toLocaleString('en-US', options);

        }
    },
    actions: {
        async fetchUser({ commit }) {
            try {
                const userRes = await axios.get('/auth/user', {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                      },
                  })
                  commit('SET_USER', userRes.data.data);
                  commit('setAmount', userRes.data.data.amount);
                  commit('setTotalWithdraw', userRes.data.totalWithdraw);
                  localStorage.setItem("userData", JSON.stringify(userRes.data.data))
                  commit('setTwodDrawTime');
                  return userRes.data.data;
            } catch (error) {
                return null;
            }
            
        },
        async logoutUser({ commit }) {
            await axios.post('/auth/logout', null, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              })
              commit('SET_USER', {});
              commit('setAmount', 0.0);
              localStorage.removeItem('userData');
              localStorage.removeItem('token');
              localStorage.removeItem('tokenExpire');
        },
    },
    getters: {
        getFBOrder(state) {
            return state.fb_order;
        },
    },
});

export default store;
