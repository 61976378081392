<template>
    <div>
        <!-- Page content -->
        <div class="ml-auto mr-auto">
            <div class="card card-upgrade mb-0">
                <div
                    class="
                        card-header
                        text-center
                        font-weight-bold
                        border-bottom-0
                    "
                >
                    {{
                        $store.state.language === "en" ? "Betslip" : "ဘောက်ချာ"
                    }}
                </div>

                <div class="card-body pt-0" v-if="type === 'normal'">
                    <div class="d-flex justify-content-between">
                        <div v-show="isTwod">
                            For
                            <b
                                >{{ currentDate }} -
                                {{ datas.length > 0 ? ( datas[0].draw === '12:00' ? $store.state.morningDrawTime : $store.state.eveningDrawTime ) : "" }}</b
                            >
                        </div>
                        <div v-show="!isTwod">
                            For
                            <b>{{ threedDrawDate }}</b>
                        </div>
                        <div>
                            <b>{{ currentTime }}</b>
                        </div>
                    </div>
                    <div class="mb-3 mt-2">
                        <b>{{ $store.state.authUser.name }}, {{ remark }}</b>
                    </div>
                    <div class="mb-3 mt-2">
                        {{
                            $store.state.language === "en"
                                ? "Slip id"
                                : "ဘောက်ချာနံပါတ်"
                        }}
                        -
                        <b>{{ slipId }}</b>
                    </div>
                    <div class="table-responsive">
                        <table style="width: 100%">
                            <thead>
                                <tr
                                    style="
                                        border-bottom: 1px solid
                                            #rgb(92, 95, 147);
                                    "
                                >
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Number"
                                                : "ဂဏန်း"
                                        }}
                                    </th>
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "ငွေပမာဏ"
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in datas" :key="index">
                                    <td class="text-center">
                                        {{ data.number }}
                                    </td>
                                    <td class="text-center">
                                        {{ data.solo_amount }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Boards"
                                                : "အရေအတွတ်"
                                        }}
                                        : {{ datas.length }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Total"
                                                : "စုစုပေါင်းငွေပမာဏ"
                                        }}
                                        : {{ totalAmount }}
                                        Unit
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center mt-2">
                            <h2>
                                {{
                                    $store.state.language === "en"
                                        ? "We will give as slip."
                                        : "စလစ်အတိုင်းသာ လျော်မည်။"
                                }}
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0" v-else>
                    <div class="d-flex justify-content-between">
                        <div>
                            <span v-show="isTwod"> For</span>
                            <b v-show="isTwod"
                                >{{ historyDate(currentTime) }} -
                                {{ datas.length > 0 ? datas[0].draw : "" }}</b
                            >
                            <b v-show="!isTwod">
                                {{ name }}
                            </b>
                        </div>
                        <div>
                            {{
                                $store.state.language === "en"
                                    ? "Bet Time"
                                    : "ထိုးချိန်"
                            }}
                            : <b>{{ currentTime }} </b>
                        </div>
                    </div>
                    <div class="mb-3 mt-2" v-show="isTwod">
                        <b>{{ name }}, {{ remark }}</b>
                    </div>
                    <div class="mb-3 mt-2">
                        {{
                            $store.state.language === "en"
                                ? "Slip id"
                                : "ဘောက်ချာနံပါတ်"
                        }}
                        - <b>{{ slipId }}</b>
                    </div>
                    <div class="table-responsive">
                        <table style="width: 100%">
                            <thead>
                                <tr
                                    style="
                                        border-bottom: 1px solid
                                            #rgb(92, 95, 147);
                                    "
                                >
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Number"
                                                : "ဂဏန်း"
                                        }}
                                    </th>
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "ငွေပမာဏ"
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in datas" :key="index">
                                    <td class="text-center">
                                        {{ data.number }}
                                    </td>
                                    <td class="text-center">
                                        {{ data.amount }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Boards"
                                                : "အရေအတွတ်"
                                        }}
                                        : {{ datas.length }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Total"
                                                : "စုစုပေါင်းငွေပမာဏ"
                                        }}
                                        : {{ totalAmountHistory }}
                                        Unit
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center mt-2">
                            <h2>
                                {{
                                    $store.state.language === "en"
                                        ? "We will give as slip."
                                        : "စလစ်အတိုင်းသာ လျော်မည်။"
                                }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: [
    'type',
    'datas',
    'slip',
    'currentTime',
    'isTwod',
    'slipId',
    'remark',
    'name',
  ],
  methods: {
    historyDate(time) {
      return moment(time).format('l');
    },
  },
  computed: {
    threedDrawDate() {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      if (currentDay < 17) {
        currentDate.setDate(16);
        return moment(currentDate).format('l');
      }
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() + 1);
      return moment(currentDate).format('ll');
    },
    totalAmount() {
      let total = 0;
      for (let i = 0; i < this.datas.length; i += 1) {
        total += parseInt(this.datas[i].solo_amount, 10);
      }
      return total;
    },
    totalAmountHistory() {
      let total = 0;
      for (let i = 0; i < this.datas.length; i += 1) {
        total += parseInt(this.datas[i].amount, 10);
      }
      return total;
    },

    currentDate() {
      return moment(new Date()).format('L');
    },
  },
};
</script>
<style scoped>
.action-icons {
    font-size: 15px;
}
td i {
    font-size: 18px;
    padding: 5px;
    color: #2dce89;
    cursor: pointer;
}
td i:hover {
    color: grey;
}
td .fa-trash {
    color: red;
}
</style>
