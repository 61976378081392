<template>
    <div>
        <!-- Page content -->
        <div class="ml-auto mr-auto card-upgrade">
            <div>
                <div
                    class="
                        card-header
                        text-center
                        font-weight-bold
                        border-bottom-0
                         text-dark font-weight-600
                    "
                >
                    {{
                        $store.state.language === "en"
                            ? "Betslip Preview"
                            : "ဘောက်ချာကြိုတင်မြင်ရမူ"
                    }}
                </div>
                <div class="card-body pt-0 text-sm">
                    <div class="mb-3 d-flex justify-content-between text-dark font-weight-600">
                        <div>
                            <div v-if="isTwod">
                                Date :
                                <b
                                    >{{ currentDate }} -
                                    {{ datas[0] ? ( datas[0].draw === '12:00' ? $store.state.morningDrawTime : $store.state.eveningDrawTime ) : "" }}</b
                                >
                            </div>
                            <div v-else>
                                Date :
                                <b>{{ threedDrawDate("ll") }}</b>
                            </div>
                        </div>
                        <div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Boards"
                                        : "အရေအတွတ်"
                                }}
                                : <b>{{ datas.length }}</b>
                            </div>

                            <div v-if="type === 'comissioner'">
                                {{
                                    $store.state.language === "en"
                                        ? "Total"
                                        : "စုစုပေါင်းငွေ"
                                }}
                                :
                                <b
                                    >{{ totalAmountWithoutComission }}
                                    Unit
                                </b>
                            </div>
                            <div v-else>
                                {{
                                    $store.state.language === "en"
                                        ? "Total"
                                        : "စုစုပေါင်းငွေ"
                                }}
                                :
                                <b
                                    >{{ totalAmount() }}
                                    Unit</b
                                >
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive text-dark font-weight-600">
                        <table style="width: 100%">
                            <thead>
                                <tr
                                    style="
                                        border-bottom: 1px solid
                                            #rgb(92, 95, 147);
                                    "
                                >
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Number"
                                                : "ဂဏန်း"
                                        }}
                                    </th>
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "ငွေပမာဏ"
                                        }}
                                    </th>
                                    <th class="text-center">
                                        {{
                                            $store.state.language === "en"
                                                ? "Action"
                                                : "လုပ်ဆောင်မူ"
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in datas" :key="index">
                                    <td class="text-center">
                                        <div v-if="isEdit !== index">
                                            {{ data.number }}
                                        </div>
                                        <input
                                            type="number"
                                            name=""
                                            id=""
                                            v-model="data.number"
                                            v-else
                                            class="form-control text-center"
                                            @change="
                                                changeParentAmount(
                                                    data.solo_amount,
                                                    index
                                                )
                                            "
                                        />
                                    </td>
                                    <td class="text-center">
                                        <div v-if="isEdit !== index">
                                            {{ data.solo_amount }}
                                        </div>

                                        <input
                                            type="number"
                                            name=""
                                            id=""
                                            v-else
                                            v-model="data.solo_amount"
                                            class="form-control text-center"
                                            @change="
                                                changeParentAmount(
                                                    data.solo_amount,
                                                    index
                                                )
                                            "
                                        />
                                    </td>

                                    <td class="p-0 d-flex justify-content-end">
                                        <div>
                                            <i
                                                class="fas fa-edit"
                                                @click="isEdit = index"
                                                v-if="isEdit !== index"
                                            ></i>
                                            <i
                                                class="fas fa-check"
                                                @click="isEdit = null"
                                                v-else
                                            ></i>
                                        </div>
                                        <div @click="removeNumber(index)">
                                            <i class="fas fa-trash"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{
                                            $store.state.language === "en"
                                                ? "Bet Close"
                                                : "ပိတ်ချိန်"
                                        }}

                                        :
                                        <b v-show="isTwod">{{
                                            twod.latest_bet_m ? betClose() : ""
                                        }}</b>
                                        <b v-show="!isTwod">{{
                                            threedDrawDate("l") +
                                            " : " +
                                            (threed.latest_bet
                                                ? threedBetClose()
                                                : "")
                                        }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex">
                            <button
                                class="
                                    btn btn-round btn-primary
                                    buttons
                                    col-4
                                    mt-3
                                    mx-auto
                                "
                                @click="finalBet"
                            >
                                <div v-if="!isBet">
                                    {{
                                        $store.state.language === "en"
                                            ? "Bet"
                                            : "ထိုးမည်"
                                    }}
                                </div>
                                <Loading
                                    color="#ffffff"
                                    v-if="isBet"
                                    size="24"
                                    class="px-2 py-0"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Dialog, Loading, Toast } from 'vant';
import { mapActions } from 'vuex';
import createDateWithTimeZone from '../../../libs/createDateWithTimeZone';

export default {
  components: { Loading },
  props: ['type', 'datas', 'isTwod', 'twod'],
  data() {
    return {
      isEdit: null,
      remark: null,
      newRemark: null,
      isBet: false,
      voucherId: this.slipId(),
      threed: {},
    //   twod: {},
      betCloseTime: {},
      threedCloseTime: {},
      newDatas: {},
      voucherLength: null,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    changeParentAmount(value, index) {
      this.datas[index].solo_amount = value;
    },
    closeModal() {
      $('#voucher').modal('hide');
    },
    removeNumber(id) {
      this.datas.splice(id, 1);
    },
    changeOverLimitedNumberAmount(list) {
      for (let i = 0; i < list.length; i += 1) {
        const index = this.newDatas.findIndex(
          (data) => data.number === list[i].number,
        );
        this.newDatas[index].solo_amount = list[i].amount;
        console.log(this.newDatas);
      }
    },
    removeClosedNumbers(list, limitedAmounts = null) {
      if (limitedAmounts) {
        this.changeOverLimitedNumberAmount(limitedAmounts);
      }
      for (let i = 0; i < list.length; i += 1) {
        const index = this.newDatas.findIndex(
          (data) => data.number === list[i],
        );
        console.log(index);
        this.newDatas.splice(index, 1);
      }
      $('#voucher').modal('show');
    },

     /* eslint-disable */
     async finalBet() {
            try {
                if(!this.isTwod) {
                    const now = moment(await createDateWithTimeZone(), 'YYYY-MM-DD HH:mm');
                    const specificDateTime = moment(this.threed.draw_date+' '+this.threed.latest_bet, 'YYYY-MM-DD HH:mm');
                    // Compare
                    if (now.isAfter(specificDateTime)) {
                        const dataError =
                            this.$store.state.language === "en"
                                ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )";
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = null;
                        return false;
                    }
                } 

            this.betClose();
            const currentDate = await createDateWithTimeZone();
            const newDate = await createDateWithTimeZone();
            
            if (this.isBet) {
                return false;
            }
            if (this.voucherId === this.slipId()) {
                return false;
            }
            this.isBet = true;
            const userRes = await this.fetchUser();
            if (!userRes) {
                $("#mobile-voucher").modal("hide");
                this.$router.push('/auth/login');
            }
            this.voucherId = this.slipId();
            this.$store.commit("setAmount", userRes.amount);

            if (this.isTwod) {
                    newDate.setHours(
                        parseInt(this.betCloseTime.hour, 10),
                        parseInt(this.betCloseTime.minute, 10),
                        0
                    );
                }

                console.log(currentDate, newDate);
            if (this.isTwod && currentDate > newDate) {
                const dataError =
                    this.$store.state.language === "en"
                        ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                        : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )";
                this.dialog(dataError);
                this.isBet = false;
                this.voucherId = null;
                return false;
            }
            
                if (this.datas.length > 0) {
                    this.newRemark = JSON.parse(JSON.stringify(this.remark));

                    for (let i = 0; i < this.datas.length; i += 1) {
                        this.datas[i].bet_date = await this.currentTime();
                    }
                    if (
                        this.$store.state.amount >= this.totalAmount() ||
                        this.type === "admin"
                    ) {
                        const res = await axios.post("/vouchers", {
                            datas: this.datas,
                            voucherId: this.voucherId,
                            betTime: await this.currentTime(),
                            isTwod: this.isTwod,
                            remark: this.remark,
                            draw_date: this.isTwod
                                ? null
                                : this.drawDate,
                                }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            },
                        });
                        this.checkCurrentVouchers();
                        if (res.data.message) {
                            this.dialog(
                                this.$store.state.language === "en"
                                    ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                    : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )"
                            );
                        } else {
                            if (res.data.overLimitNumbers.length > 0) {
                                const dataError = `${
                                    this.$store.state.language === "en"
                                        ? "Limited number amount"
                                        : "ကန့်သတ်ထားသောနံပါတ်နှင့် ငွေပမာဏ"
                                } : ${JSON.stringify(res.data.overLimitNumbers)
                                    .replace(
                                        /[`~!@#$%^&*()_|+\-=?;'".<>\{\[\]\/]/gi,
                                        ""
                                    )
                                    .replace(/[\}\\\/]/g, ", ")
                                    .toString()
                                    .bold()
                                    .fontsize(4)}`;
                                this.dialog(dataError);
                                this.removeClosedNumbers(
                                    res.data.closedNumbers,
                                    res.data.overLimitNumbers
                                );
                            } else {
                                this.removeClosedNumbers(
                                    res.data.closedNumbers
                                );
                            }

                            if (this.type !== "admin") {
                                this.$store.commit(
                                    "setAmount",
                                    (this.$store.state.amount -=
                                        this.totalAmount())
                                );
                                await this.fetchUser();
                            }

                            if (res.data.closedNumbers.length > 0) {
                                const dataError = `${
                                    this.$store.state.language === "en"
                                        ? "Closed number(s)"
                                        : "ပိတ်ထားသော နံပါတ်များ"
                                } : ${res.data.closedNumbers
                                    .toString()
                                    .bold()
                                    .fontsize(4)}`;
                                this.dialog(dataError);
                            }

                            Toast.success(
                                this.$store.state.language === 'en'
                                    ? 'Success'
                                    : 'Success',
                                );
                            this.$emit("remove", "");
                            this.remark = null;
                        }
                    } else {
                        const balanceError =
                            this.$store.state.language === "en"
                                ? "You Don't Have Sufficient Balance"
                                : "အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။";
                        this.dialog(balanceError);
                    }
                } else {
                    const dataError =
                        this.$store.state.language === "en"
                            ? "Please pick some numbers!"
                            : "နံပါတ်အချို့ ရွေးပေးပါ။";
                    this.dialog(dataError);
                }
                this.isBet = false;
                this.voucherId = null;
            } catch (error) {
                console.log(error);
                this.isBet = false;
                this.voucherId = null;
            }
            return true;
        },

        drawDateAsTimestamp() {
            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            if (currentDay < 17 && currentDay !== 1) {
                currentDate.setDate(16);
                return moment(currentDate).format();
            }
            currentDate.setDate(1);
            currentDate.setMonth(currentDate.getMonth() + 1);
            return moment(currentDate).format();
        },
        dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },

        currentTime() {
            return moment(new Date()).format("lll");
        },
        totalAmount() {
            let total = 0;
            for (let i = 0; i < this.newDatas.length; i += 1) {
                total += parseInt(this.newDatas[i].solo_amount, 10);
            }
            if (this.$auth.check("comissioner")) {
                const comissionerValue =
                    total *
                    ((this.isTwod
                        ? this.$store.state.authUser["2d_comission"]
                        : this.$store.state.authUser["3d_comission"]) /
                        100);
                total -= comissionerValue;
            }
            return total;
        },
        async threedData() {
            try {
                const res = await axios.get("3d/data");
                this.threed = res.data.data[0];
            } catch (error) {
                console.log(error);
            }
        },
        async twodData() {
            try {
                const res = await axios.get("2d/data");
                this.twod = res.data.data[0];
            } catch (error) {
                console.log(error);
            }
        },
        threedDrawDate(type) {
            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            if (currentDay < 17 && currentDay !== 1) {
                currentDate.setDate(16);
                return moment(currentDate).format(type);
            }
            currentDate.setDate(1);
            currentDate.setMonth(currentDate.getMonth() + 1);
            return moment(currentDate).format(type);
        },
        threedBetClose() {
            const newBetTimeArray = this.threed.latest_bet
                .toString(10)
                .split("")
                .map((t) => t);

            this.threedCloseTime.hour = newBetTimeArray[0].toString();
            this.threedCloseTime.minute =
                newBetTimeArray[2].toString() + newBetTimeArray[3].toString();
            return `${this.threed.latest_bet} PM`;
        },
        betClose() {
            const draw = this.datas[0]?.draw;
            const now = new Date();
            if (draw === '12:00') {
                const newBetTimeArray = this.twod.latest_bet_m?.toString(10)
                .split(':');
                
                const morning = new Date(Date.UTC(
                    now.getFullYear(),  // Local year
                    now.getMonth(),     // Local month (0-indexed)
                    now.getDate(),
                    5,  // hours
                    30 - ( 60 - newBetTimeArray[1] ), // minutes
                    0   // seconds
                ));
                return morning.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            } 
        
            const eveningBetTimeArray = this.twod.latest_bet_e?.toString(10)
                .split(':');
        
                let eveningHour = 10;
                let eveningMinute = 0;
                if(eveningBetTimeArray && eveningBetTimeArray[0] && eveningBetTimeArray[1]) {
                    if(eveningBetTimeArray[0] == 15 && eveningBetTimeArray[1] < 30){
                        eveningHour = 9 - (16 - eveningBetTimeArray[0]);
                        eveningMinute = 30 + eveningBetTimeArray[1];
                    } else if(eveningBetTimeArray[0] == 15 && eveningBetTimeArray[1] > 30){
                        eveningHour = 10 - (16 - eveningBetTimeArray[0]);
                        eveningMinute = eveningBetTimeArray[1] - 30;
                    } else if(eveningBetTimeArray[0] == 16){
                        eveningHour = 9;
                        eveningMinute = 60 - eveningBetTimeArray[1];
                    }
                }
                
                const evening = new Date(Date.UTC(
                    now.getFullYear(),  // Local year
                    now.getMonth(),     // Local month (0-indexed)
                    now.getDate(),
                    eveningHour,  // hours
                    eveningMinute, // minutes
                    0   // seconds
                ));
        
                return evening.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            },
        async checkCurrentVouchers() {
            try {
                const res = await axios.post("/vouchers/check", {
                    type: this.isTwod ? "twod" : "threed",
                }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
                this.voucherLength = res.data.voucherLength;
            } catch (error) {
                console.log(error);
            }
        },
        slipId() {
            return this.voucherLength;
        },
        onlyTotalAmount() {
            let total = 0;
            for (let i = 0; i < this.datas.length; i += 1) {
                total += parseInt(this.datas[i].solo_amount, 10);
            }
            return total;
        },
    },
    computed: {
        totalAmountWithoutComission() {
            let total = 0;
            for (let i = 0; i < this.datas.length; i += 1) {
                total += parseInt(this.datas[i].solo_amount, 10);
            }
            return total;
        },

        currentDate() {
            return moment(new Date()).format("l");
        },
    },
    watch: {
        async datas() {
            if (this.isTwod) {
                this.$store.commit("setTwodSlip", this.datas);
            } else {
                this.$store.commit("setThreedSlip", this.datas);
            }
            if (this.datas.length > 0) {
                this.newDatas = JSON.parse(JSON.stringify(this.datas));
            }
        },
    },
    destroyed() {
        this.$store.commit("setTwodSlip", []);
        this.$store.commit("setThreedSlip", []);
    },
    mounted() {
        this.checkCurrentVouchers();
        this.threedData();
        // this.twodData();
    },
};
</script>
<style scoped>
.btn{
    background: #009646 !important;
    color: white !important;
    font-size: 1rem; 
    /* height: 2.2rem !important; */
    margin-top: 2rem !important;
    box-shadow: none !important;
}
.card-upgrade {
    min-height: 400px;
}
.card-body {
    padding-bottom: 0px;
}
.action-icons {
    font-size: 15px;
}
td i {
    font-size: 18px;
    padding: 5px;
    color: #2dce89;
    cursor: pointer;
}
td i:hover {
    color: grey;
}
td .fa-trash {
    color: red;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
    .hidden {
        display: none;
    }
    .card-upgrade {
        min-height: 100px;
    }
    .form-control {
        height: 2.5rem !important;
    }
}
</style>
