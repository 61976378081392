<template>
    <div class="pt-5" style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/luungal/icons/bg.png'); background-attachment: fixed; min-height: 100vh; height: auto; background-size: cover;">
      <!-- <h4 class="text-center mb-3">
        <span class="text-success text-lg">{{
          footballLiveMatch.home_team_name
        }}</span>
        <small class="text-sm text-danger"> VS </small>
        <span class="text-success text-lg">{{
          footballLiveMatch.away_team_name
        }}</span>
      </h4> -->
      <div class="p-1 px-2 d-flex" style="height: 28vh;">
        <!-- <div
          class="mx-auto p-0 d-flex main-image-wrapper mt-2 pt-1"
          style="border-radius: 5px;"
          v-html="footballLink"
        ></div> -->
        <video
          id="video-player"
          class="video-js vjs-default-skin"
          controls
          autoplay
          preload="auto"
          ref="videoPlayer"
          style="width: 100%; height: auto"
        ></video>
      </div>
      <div class="d-flex justify-content-center flex-wrap mt-4">
        <button
          class="col-4 btn btn-danger text-lg text-uppercase mx-2"
          style="margin-top: 1rem !important; background: #FF5800 !important;"
          v-for="(link, index) in servers"
          :key="index"
          @click="changeLink(link.stream_url)"
        >
          Link - {{ index + 1 }}
        </button>
      </div>
    </div>
  </template>

<script>
import { mapActions } from 'vuex';
import videojs from "video.js";
import "video.js/dist/video-js.css";
  
  export default {
    data() {
      return {
        footballLiveMatch: {},
        servers: JSON.parse(localStorage.getItem('servers')),
        footballLink: null
      };
    },
    methods: {
      ...mapActions(['fetchUser']),
      changeLink(link) {
      // this.footballLink = `<iframe src='https://footballapi.spacetechmm.com/free-stream?url=${link}' width='100%' height='300px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;

      // const proxiedUrl = `http://localhost:8000/api/proxied-m3u8?url=${encodeURIComponent(link)}`;

      if (this.player) {
        // Update the source
        this.player.src({
          src: link,
          type: "application/x-mpegURL",
        });
        // Optionally play the video
        this.player.play();
      }
    },
      processPause(progress) {
        console.log(progress);
      },
      // async fetchMatches() {
      //   try {
      //     const res = await axios.get('football-live-matches');
      //     this.footballLiveMatch = res.data.data[this.$route.query.index];
      //     this.footballLink = `<iframe src='https://t.fdcdn.xyz/v11/?id=KEsECpu&theme_id=vebotv&t=1&n=SD1&c=24&link=${this.servers[0]?.link}' width='100%' height='300px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    },
    async mounted() {
      // const proxiedUrl = `http://localhost:8000/api/proxied-m3u8?url=${encodeURIComponent(this.servers[0].stream_url)}`;
      console.log(this.servers[0]?.stream_url)
        this.player = videojs(this.$refs.videoPlayer, {
          controls: true,
          autoplay: true,
          preload: "auto",
          sources: [
            {
              src: this.servers[0]?.stream_url,
              type: "application/x-mpegURL",
            },
          ],
        });

        this.player.ready(() => {
          console.log("Video.js player is ready.");
        });

        this.player.on("error", (e) => {
          console.error("Video.js error:", e);
        });
        
      // this.footballLink = `<iframe src='https://footballapi.spacetechmm.com/free-stream?url=${this.servers[0]?.link}' width='100%' height='300px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
      // this.footballLink = 'https://t.fdcdn.xyz/v11/?link=https%3A%2F%2Ffdcdn.livestreameuro.live%2Flive%2FdungHD.flv%3FwsSecret%3D46675878f5abb91642d698dc860dd5e1%26wsTime%3D1736853962%26keeptime%3D21600&id=ZfAxFZq&n=HD Nhanh&t=1&theme_id=vebotv&c=HruHBx3&df=https%3A%2F%2Fhotlivezz179569584.akainu.xyz%2FJTdm8AoRNDpOfZ3o-8jTrA%2FFv2lucGIbhPi2caWYvF5Lg%2F1736875562181%2Flive%2FdungHD%2Fplaylist.m3u8'
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      // this.fetchMatches();
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
  };
  </script>